<div style="display: flex; flex-direction: row; width: 100%; height: 100%" [formGroup]="configurationForm">
  <div class="tab-body-left" style="display: flex; flex-direction: column" formArrayName="subRegionsForm">
    <ng-container *ngIf="subRegions.length < 4">
      <button mat-button class="add-sub-region-btn" (click)="is3DViewSelected ? addSubRegion() : drawNewSubRegion()">
        <img src="../assets/icons/plus-icon.svg" class="plus-icon" />
        <span style="margin-left: 10px" i18n="@@add-sub-region">Add Sub Region</span>
      </button>
    </ng-container>
    <hr>
    <mat-accordion cdkDropList (cdkDropListDropped)="reorder($event)" [cdkDropListDisabled]="selectedSubRegionIndex !== null" displayMode="flat" togglePosition="before" class="sub-regions-list">
        <mat-expansion-panel 
          *ngFor="let subRegion of subRegions.controls; let index = index"
          [expanded]="selectedSubRegionIndex === index"
          class="subregion-block"
          style="background: transparent; box-shadow: none;"
          (opened)="selectedSubRegionIndex = index"
          (closed)="selectedSubRegionIndex === index ? selectedSubRegionIndex = null : 0"
          cdkDrag
        >
          <mat-expansion-panel-header style="position: relative">
            <mat-panel-title>{{subRegion.controls.name.value}}</mat-panel-title>
            <div cdkDragHandle [class]="selectedSubRegionIndex === null?'grippy':'grippy-disabled'" class="grippy-icon"></div>
          </mat-expansion-panel-header>
          <div [formGroupName]="index">
            <div class="subregion-fields-title-row">
              <span class="field-title" i18n="@@name">Name</span>
              <button mat-icon-button class="field-title remove-btn" (click)="removeSubRegion(index)">
                <mat-icon class="material-icons-outlined">delete</mat-icon>
              </button>
            </div>
            <mat-form-field appearance="outline" class="value-field">
              <input
                          matInput
                          name="name"
                          placeholder="Type Name Here"
                          required
                          formControlName="name"
                          i18n-placeholder="@@type-name-here"
              />
            </mat-form-field>
            <div *ngIf="enableDoorEvents && (roomType === RoomType.Bedroom ? (index !== 0) : true)" class="item-config" style="margin-bottom: 1.6em;" data-cy="isDoor">
              <span>
                <span class="field-title" i18n="@@door-settings">Door Settings (v40)</span>
                <span class="cdk-visually-hidden" #doorSettingsTooltip i18n="@@door-Settings-tooltip">
                  Enables indications of room occupancy/vacancy according to the detection of movement across the entrance of the room (“Door Events” must be enabled for the device).
                </span>
                <mat-icon color="primary" class="info-icon" [matTooltip]="doorSettingsTooltip.innerHTML" matTooltipClass="tooltip" matTooltipPosition="right">
                  info
                </mat-icon>
              </span>
              <mat-slide-toggle formControlName="isDoor" (change)="onDoorSettingsChange($event, index)"></mat-slide-toggle>
            </div>
            <div *ngIf="enableBedSettings && index === 0" class="item-config" style="margin-bottom: 1.6em;" data-cy="bedSettings">
              <span>
                <span class="field-title" i18n="@@bed-settings">Bed Settings (v40)</span>
                <span class="cdk-visually-hidden" #bedSettingsTooltip i18n="@@bed-settings-tooltip">
                  Empty bed event is triggered once the person moves away from the defined bed subregion and the radar confirms that there is no indication of human presence in the subregion. Supported for ceiling installation only (“Empty Bed Events” must be enabled for the device).
                </span>
                <mat-icon color="primary" class="info-icon" [matTooltip]="bedSettingsTooltip.innerHTML" matTooltipClass="tooltip" matTooltipPosition="right">
                  info
                </mat-icon>
              </span>
              <mat-slide-toggle formControlName="bedSettings" (change)="onBedSettingsChange($event, index)"></mat-slide-toggle>
            </div>
            <span class="fields-title-big" i18n="@@measure-points-title">Measure these points from the device</span
            >
            <!--  Most Left Point and Most Right Point-->
            <div class="grid-div" style="padding-bottom: 5px; padding-top: 10px">
              <div class="subregion-fields-row" *ngIf="unit === metric">
                <div style="width: 45%">
                  <mat-form-field appearance="outline" class="value-field">
                    <input
                            matInput
                            type="number"
                            name="x1"
                            formControlName="xMin"
                            (ngModelChange)="validateRoomSizeAgainstSubregionChanged('xMin')"
                            [errorStateMatcher]="xMinValMatcher"
                    />
                    <span matPrefix>xMin</span>
                    <span matSuffix>m</span>
                    <mat-error *ngIf="subRegions.invalid">
                      {{ getSubregionErrorMessage(index, "xMin") }}
                    </mat-error>
                  </mat-form-field>
                </div>
                <div style="width: 45%">
                  <mat-form-field appearance="outline" class="value-field">
                    <input
                            matInput
                            type="number"
                            name="x2"
                            formControlName="xMax"
                            (ngModelChange)="validateRoomSizeAgainstSubregionChanged('xMax')"
                            [errorStateMatcher]="xMaxValMatcher"
                    />
                    <span matPrefix>xMax</span>
                    <span matSuffix>m</span>
                    <mat-error *ngIf="subRegions.invalid">
                      {{ getSubregionErrorMessage(index, "xMax") }}
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>

              <div class="subregion-fields-row subregion-feet-inches-row" *ngIf="unit !== metric">
                <div style="width: 45%">
                  <mat-form-field appearance="outline" class="value-field feet-field">
                    <input
                            matInput
                            type="number"
                            name="x1Feet"
                            formControlName="xMinFeet"
                            (ngModelChange)="validateRoomSizeAgainstSubregionChanged('xMin')"
                            (keypress)="filterNegativeValueFeet($event, 'xMinInches', index)"
                            [errorStateMatcher]="xMinValMatcher"
                    />
                    <span matPrefix>xMin</span>
                    <span matSuffix>'</span>
                    <mat-error *ngIf="subRegions.invalid">
                      {{ getSubregionErrorMessage(index, "xMin") }}
                    </mat-error>
                  </mat-form-field>
                  <div class="inches-field-wrapper">
                    <mat-form-field appearance="outline" class="value-field inches-field">
                      <input
                              matInput
                              type="number"
                              name="x1Inches"
                              formControlName="xMinInches"
                              (ngModelChange)="validateRoomSizeAgainstSubregionChanged('xMin')"
                              (keypress)="filterNegativeValueInches($event, 'xMinFeet', index)"
                              [errorStateMatcher]="xMinValMatcher"
                      />
                      <span matSuffix>"</span>
                      <mat-error *ngIf="subRegions.invalid">
                        {{ getSubregionInchesErrorMessage(index, "xMin") }}
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>

                <div style="width: 45%">
                  <mat-form-field appearance="outline" class="value-field feet-field">
                    <input
                            matInput
                            type="number"
                            name="x2Feet"
                            formControlName="xMaxFeet"
                            (ngModelChange)="validateRoomSizeAgainstSubregionChanged('xMax')"
                            (keypress)="filterNegativeValueFeet($event, 'xMaxInches', index)"
                            [errorStateMatcher]="xMaxValMatcher"
                    />
                    <span matPrefix>xMax</span>
                    <span matSuffix>'</span>
                    <mat-error *ngIf="subRegions.invalid">
                      {{ getSubregionErrorMessage(index, "xMax") }}
                    </mat-error>
                  </mat-form-field>
                  <div class="inches-field-wrapper">
                    <mat-form-field appearance="outline" class="value-field inches-field">
                      <input
                              matInput
                              type="number"
                              name="x2Inches"
                              formControlName="xMaxInches"
                              (ngModelChange)="validateRoomSizeAgainstSubregionChanged('xMax')"
                              (keypress)="filterNegativeValueInches($event, 'xMaxFeet', index)"
                              [errorStateMatcher]="xMaxValMatcher"
                      />
                      <span matSuffix>"</span>
                      <mat-error *ngIf="subRegions.invalid">
                        {{ getSubregionInchesErrorMessage(index, "xMax") }}
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>

            <!-- Closest point and Farthest point-->
            <div class="grid-div">
              <div class="subregion-fields-row" *ngIf="unit === metric">
                <div style="width: 45%">
                  <mat-form-field appearance="outline" class="value-field">
                    <input
                            matInput
                            type="number"
                            name="y1"
                            formControlName="yMin"
                            (ngModelChange)="validateRoomSizeAgainstSubregionChanged('yMin')"
                            [errorStateMatcher]="yMinValMatcher"
                    />
                    <span matPrefix>yMin</span>
                    <span matSuffix>m</span>
                    <mat-error *ngIf="subRegions.invalid">
                      {{ getSubregionErrorMessage(index, "yMin") }}
                    </mat-error>
                  </mat-form-field>
                </div>
                <div style="width: 45%">
                  <mat-form-field appearance="outline" class="value-field">
                    <input
                            matInput
                            type="number"
                            name="y2"
                            formControlName="yMax"
                            (ngModelChange)="validateRoomSizeAgainstSubregionChanged('yMax')"
                            [errorStateMatcher]="yMaxValMatcher"
                    />
                    <span matPrefix>yMax</span>
                    <span matSuffix>m</span>
                    <mat-error *ngIf="subRegions.invalid">
                      {{ getSubregionErrorMessage(index, "yMax") }}
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>

              <div class="subregion-fields-row subregion-feet-inches-row" *ngIf="unit !== metric">
                <div style="width: 45%">
                  <mat-form-field appearance="outline" class="value-field feet-field">
                    <input
                            matInput
                            type="number"
                            name="y1Feet"
                            formControlName="yMinFeet"
                            (ngModelChange)="validateRoomSizeAgainstSubregionChanged('yMin')"
                            (keypress)="filterNegativeValueFeet($event, 'yMinInches', index)"
                            [errorStateMatcher]="yMinValMatcher"
                    />
                    <span matPrefix>yMin</span>
                    <span matSuffix>'</span>
                    <mat-error *ngIf="subRegions.invalid">
                      {{ getSubregionErrorMessage(index, "yMin") }}
                    </mat-error>
                  </mat-form-field>
                  <div class="inches-field-wrapper">
                    <mat-form-field appearance="outline" class="value-field inches-field">
                      <input
                              matInput
                              type="number"
                              name="y1Inches"
                              formControlName="yMinInches"
                              (ngModelChange)="validateRoomSizeAgainstSubregionChanged('yMin')"
                              (keypress)="filterNegativeValueInches($event, 'yMinFeet', index)"
                              [errorStateMatcher]="yMinValMatcher"
                      />
                      <span matSuffix>"</span>
                      <mat-error *ngIf="subRegions.invalid">
                        {{ getSubregionInchesErrorMessage(index, "yMin") }}
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>

                <div style="width: 45%">
                  <mat-form-field appearance="outline" class="value-field feet-field">
                    <input
                            matInput
                            type="number"
                            name="y2Feet"
                            formControlName="yMaxFeet"
                            (ngModelChange)="validateRoomSizeAgainstSubregionChanged('yMax')"
                            (keypress)="filterNegativeValueFeet($event, 'yMaxInches', index)"
                            [errorStateMatcher]="yMaxValMatcher"
                    />
                    <span matPrefix>yMax</span>
                    <span matSuffix>'</span>
                    <mat-error *ngIf="subRegions.invalid">
                      {{ getSubregionErrorMessage(index, "yMax") }}
                    </mat-error>
                  </mat-form-field>
                  <div class="inches-field-wrapper">
                    <mat-form-field appearance="outline" class="value-field inches-field">
                      <input
                              matInput
                              type="number"
                              name="y2Inches"
                              formControlName="yMaxInches"
                              (ngModelChange)="validateRoomSizeAgainstSubregionChanged('yMax')"
                              (keypress)="filterNegativeValueInches($event, 'yMaxFeet', index)"
                              [errorStateMatcher]="yMaxValMatcher"
                      />
                      <span matSuffix>"</span>
                      <mat-error *ngIf="subRegions.invalid">
                        {{ getSubregionInchesErrorMessage(index, "yMax") }}
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>

            <!-- Most Bottom Point and  Most Top Point-->
            <div class="grid-div">
              <div class="subregion-fields-row" *ngIf="unit === metric">
                <div style="width: 45%">
                  <mat-form-field appearance="outline" class="value-field">
                    <input
                            matInput
                            type="number"
                            name="z1"
                            formControlName="zMin"
                            (ngModelChange)="updateLastUpdateTimestamp('zMin')"
                            [errorStateMatcher]="zMinValMatcher"
                    />
                    <span matPrefix>zMin</span>
                    <span matSuffix>m</span>
                    <mat-error *ngIf="subRegions.invalid">
                      {{ getSubregionErrorMessage(index, "zMin") }}
                    </mat-error>
                  </mat-form-field>
                </div>
                <div style="width: 45%">
                  <mat-form-field appearance="outline" class="value-field">
                    <input
                            matInput
                            type="number"
                            name="z2"
                            formControlName="zMax"
                            (ngModelChange)="updateLastUpdateTimestamp('zMax')"
                            [errorStateMatcher]="zMaxValMatcher"
                    />
                    <span matPrefix>zMax</span>
                    <span matSuffix>m</span>
                    <mat-error *ngIf="subRegions.invalid">
                      {{ getSubregionErrorMessage(index, "zMax") }}
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>

              <div class="subregion-fields-row subregion-feet-inches-row" *ngIf="unit !== metric">
                <div style="width: 45%">
                  <mat-form-field appearance="outline" class="value-field feet-field">
                    <input
                            matInput
                            type="number"
                            name="z1Feet"
                            formControlName="zMinFeet"
                            (ngModelChange)="updateLastUpdateTimestamp('zMin')"
                            (keypress)="filterNegativeValueFeet($event, 'zMinInches', index)"
                            [errorStateMatcher]="zMinValMatcher"
                    />
                    <span matPrefix>zMin</span>
                    <span matSuffix>'</span>
                    <mat-error *ngIf="subRegions.invalid">
                      {{ getSubregionErrorMessage(index, "zMin") }}
                    </mat-error>
                  </mat-form-field>
                  <div class="inches-field-wrapper">
                    <mat-form-field appearance="outline" class="value-field inches-field">
                      <input
                              matInput
                              type="number"
                              name="z1Inches"
                              formControlName="zMinInches"
                              (ngModelChange)="updateLastUpdateTimestamp('zMin')"
                              (keypress)="filterNegativeValueInches($event, 'zMinFeet', index)"
                              [errorStateMatcher]="zMinValMatcher"
                      />
                      <span matSuffix>"</span>
                      <mat-error *ngIf="subRegions.invalid">
                        {{ getSubregionInchesErrorMessage(index, "zMin") }}
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>

                <div style="width: 45%">
                  <mat-form-field appearance="outline" class="value-field feet-field">
                    <input
                            matInput
                            type="number"
                            name="z2Feet"
                            formControlName="zMaxFeet"
                            (ngModelChange)="updateLastUpdateTimestamp('zMax')"
                            (keypress)="filterNegativeValueFeet($event, 'zMaxInches', index)"
                            [errorStateMatcher]="zMaxValMatcher"
                    />
                    <span matPrefix>zMax</span>
                    <span matSuffix>'</span>
                    <mat-error *ngIf="subRegions.invalid">
                      {{ getSubregionErrorMessage(index, "zMax") }}
                    </mat-error>
                  </mat-form-field>
                  <div class="inches-field-wrapper">
                    <mat-form-field appearance="outline" class="value-field inches-field">
                      <input
                              matInput
                              type="number"
                              name="z2Inches"
                              formControlName="zMaxInches"
                              (ngModelChange)="updateLastUpdateTimestamp('zMax')"
                              (keypress)="filterNegativeValueInches($event, 'zMaxFeet', index)"
                              [errorStateMatcher]="zMaxValMatcher"
                      />
                      <span matSuffix>"</span>
                      <mat-error *ngIf="subRegions.invalid">
                        {{ getSubregionInchesErrorMessage(index, "zMax") }}
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>

            <!-- subRegion enterDuration and  exitDuration-->
            <div class="grid-div">
              <div class="subregion-fields-row duration">
                <div style="width: 100%">
                  <mat-form-field appearance="outline" class="value-field">
                    <input
                            matInput
                            type="number"
                            name="enterDurationSubRegion"
                            formControlName="enterDuration"
                            [errorStateMatcher]="enterDurationValMatcher"
                    />
                    <span matPrefix i18n="@@enterDuration">enterDuration</span>
                    <span matSuffix>s</span>
                    <mat-error *ngIf="subRegions.invalid">
                      {{ getDurationErrorMessage(subRegion, "enterDuration") }}
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>

              <div class="subregion-fields-row duration">
                <div style="width: 100%">
                  <mat-form-field appearance="outline" class="value-field">
                    <input
                            matInput
                            type="number"
                            name="exitDurationSubRegion"
                            formControlName="exitDuration"
                            [errorStateMatcher]="exitDurationValMatcher"
                    />
                    <span matPrefix i18n="@@exitDuration">exitDuration</span>
                    <span matSuffix>s</span>
                    <mat-error *ngIf="subRegions.invalid">
                      {{ getDurationErrorMessage(subRegion, "exitDuration") }}
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>

            <!-- Presence Detection and Fall Detection-->
            <div class="grid-div">
              <div class="item-config">
                <span class="field-title" i18n="@@presence-detection">Presence Detection</span>
                <mat-slide-toggle formControlName="isPresenceDetection">
                </mat-slide-toggle>
              </div>
              <div class="item-config">
                <span class="field-title" i18n="@@fall-detection">Fall Detection</span>
                <mat-slide-toggle formControlName="isFallingDetection"></mat-slide-toggle>
              </div>
              <div class="item-config">
                <span class="field-title">
                  <span i18n="@@resting-area">Resting Area</span>
                  <span class="cdk-visually-hidden" #translatedTooltipMessage i18n="@@resting-area-tooltip">
                    Should be enabled for regions in the room where the resident’s movement is generally static for long periods of time (such as a bed or chair).
                  </span>
                  <mat-icon color="primary" class="info-icon" [matTooltip]="translatedTooltipMessage.innerHTML" matTooltipClass="tooltip" matTooltipPosition="right">
                    info
                  </mat-icon>
                </span>
                <mat-slide-toggle formControlName="isLowSnr"></mat-slide-toggle>
              </div>
            </div>
          </div>
        </mat-expansion-panel>
    </mat-accordion>
  </div>
  <div class="tab-body-right">
    <ng-container [ngTemplateOutlet]="content?.templateRef"></ng-container>
  </div>
</div>
