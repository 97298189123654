import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { BaseComponent } from "../../../base-component";
import {
  FormGroup,
  FormArray,
  UntypedFormBuilder,
  Validators,
  ValidationErrors,
  ValidatorFn,
} from "@angular/forms";
import { MeasurementUnits } from "../../../ui.module";
import {
  TrackerSubRegion,
  DeviceConfigGen2,
  RoomType,
  SensorMounting,
} from "@walabot-mqtt-dashboard/api";

import {
  convertFeetAndInchesToMeters,
  convertMetersToFeet,
  filterNegativeValueFeet,
  filterNegativeValueInches,
  getDurationErrorMessage,
  isFeetValueGreaterOrEqualThan,
  isFeetValueLessOrEqualThan,
  modifyNegativeZeroForFeet,
  RegionErrorStateMatcher,
} from "../device-settings-utils";

import {
  ControlRoom,
  RoomSizeForm,
  RoomSizeFromForm,
  SubregionForm,
} from "../device-settings-interface";

import {
  convertToFeetSystem,
  DOOR_THICKNESS_IN_METERS,
  ROOM_LIMITS,
} from "../room-size-limit";
import { MatTabGroup } from "@angular/material/tabs";
import { ZippyContentDirective } from "../../../ng-zip.directive";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { MatSlideToggleChange } from "@angular/material/slide-toggle";
import { pairwise, startWith, takeUntil } from "rxjs/operators";

const { required } = Validators as ValidationErrors;
const DEFAULT_SUB_REGION = {
  xMin: {
    meters: -1.5,
    feet: -4,
    inches: 11,
  },
  xMax: {
    meters: 1.5,
    feet: 4,
    inches: 11,
  },
  yMin: {
    meters: 0.3,
    feet: 1,
    inches: 11.8,
  },
  yMax: {
    meters: 1.5,
    feet: 4,
    inches: 11,
  },
  zMin: {
    meters: 0,
    feet: 1,
    inches: 11.8,
  },
  zMax: {
    meters: 1.2,
    feet: 4,
    inches: 11,
  },
  enterDuration: 120,
  exitDuration: 120,
};

const MAX_VALUE_INCHES = 12;
const MIN_DURATION_VALUE = 0;

const BED_SETTINGS = {
  isFallingDetection: false,
  isPresenceDetection: true,
  isLowSnr: true,
};

const DOOR_SETTINGS = {
  zMax: 1.8,
  zMin: 0,
  isDoor: true,
  isLowSnr: false,
  isFallingDetection: true,
  isPresenceDetection: true,
};

@Component({
  selector: "app-sub-regions",
  templateUrl: "./sub-regions.component.html",
  styleUrls: ["./sub-regions.component.css"],
})
export class SubRegionsComponent
  extends BaseComponent
  implements OnInit, OnChanges
{
  @ContentChild(ZippyContentDirective) content!: ZippyContentDirective;
  @Input() configurationForm: FormGroup;
  @Input() isWallMount: boolean;
  @Input() unit: MeasurementUnits;
  @Input() config: DeviceConfigGen2;
  @Input() is3DViewSelected: boolean;
  @Input() controlLastUpdateTimestamp: ControlRoom;
  @Input() getRoomSize: () => RoomSizeFromForm;
  @Input() getSensorHeight: () => number;
  @Output() subregionChanged = new EventEmitter();
  @Output() updateTimestamp = new EventEmitter();
  @Output() drawSubRegion = new EventEmitter();

  metric = MeasurementUnits.Metric;
  selectedSubRegionIndex;
  RoomType = RoomType;

  xMinValMatcher = new RegionErrorStateMatcher([
    "xMinValueInvalid",
    "xMinInvalid",
    "xMinValueNotContainedInRoom",
    "xMinInchesInvalid",
  ]);
  yMinValMatcher = new RegionErrorStateMatcher([
    "yMinValueInvalid",
    "yMinInvalid",
    "yMinValueNotContainedInRoom",
    "yMinInchesInvalid",
  ]);
  zMinValMatcher = new RegionErrorStateMatcher([
    "zMinValueInvalid",
    "zMinInvalid",
    "zMinValueNotContainedInRoom",
    "zMinInchesInvalid",
  ]);
  xMaxValMatcher = new RegionErrorStateMatcher([
    "xMaxValueInvalid",
    "xMaxInvalid",
    "xMaxValueNotContainedInRoom",
    "xMaxInchesInvalid",
  ]);
  yMaxValMatcher = new RegionErrorStateMatcher([
    "yMaxValueInvalid",
    "yMaxInvalid",
    "yMaxValueNotContainedInRoom",
    "yMaxInchesInvalid",
  ]);
  zMaxValMatcher = new RegionErrorStateMatcher([
    "zMaxValueInvalid",
    "zMaxInvalid",
    "zMaxValueNotContainedInRoom",
    "zMaxInchesInvalid",
  ]);
  enterDurationValMatcher = new RegionErrorStateMatcher([
    "enterDurationValuesInvalid",
  ]);
  exitDurationValMatcher = new RegionErrorStateMatcher([
    "exitDurationValuesInvalid",
  ]);

  get roomSize() {
    return this.configurationForm.get(
      "roomSizeForm"
    ) as FormGroup<RoomSizeForm>;
  }

  get subRegions() {
    return this.configurationForm.get("subRegionsForm") as FormArray<FormGroup>;
  }

  get deviceSettings() {
    return this.configurationForm.get("deviceSettingsForm") as FormGroup;
  }

  get enableDoorEvents() {
    return this.deviceSettings.get("enableDoorEvents")?.value as boolean;
  }

  get enableBedSettings() {
    return (
      (this.deviceSettings.get("enableOutOfBed")?.value as boolean) &&
      this.deviceSettings.get("roomType")?.value === RoomType.Bedroom &&
      this.deviceSettings.get("sensorMounting").value ===
        SensorMounting.Ceiling45Deg
    );
  }

  get roomType() {
    return this.deviceSettings.get("roomType")?.value as RoomType;
  }

  /**
   * Checks that the subregion dimensions entered by the user do not exceed the allowable
   * maximum and minimum room parameters.
   * Permissible room parameters are set in the ROOM_LIMITS constant
   */
  get subregionSizeValidator(): ValidatorFn {
    return (group: FormGroup<SubregionForm>) => {
      const error = {};
      const sensorMounting =
        this.configurationForm &&
        this.deviceSettings &&
        this.deviceSettings.get("sensorMounting").value === SensorMounting.Wall
          ? "wall"
          : this.deviceSettings.get("sensorMounting").value ===
            SensorMounting.Ceiling45Deg
          ? "ceiling45"
          : "ceiling";
      if (this.isMetric()) {
        const yMin = group.get("yMin").value,
          yMax = group.get("yMax").value,
          xMin = group.get("xMin").value,
          xMax = group.get("xMax").value;

        const canExceedDistanceMeters =
          this.enableDoorEvents && group.get("isDoor").value
            ? DOOR_THICKNESS_IN_METERS
            : 0;
        const finalXMinValueMinMeters =
          ROOM_LIMITS[sensorMounting].XMinValueMin.meters -
          canExceedDistanceMeters;
        const finalXMaxValueMaxMeters =
          ROOM_LIMITS[sensorMounting].XMaxValueMax.meters +
          canExceedDistanceMeters;
        const finalYMaxValueMaxMeters =
          ROOM_LIMITS[sensorMounting].YMaxValueMax.meters +
          canExceedDistanceMeters;
        if (xMin < finalXMinValueMinMeters) {
          error["xMinInvalid"] =
            this.getErrorMessageValueShouldBeGreaterOrEqualThan(
              finalXMinValueMinMeters
            );
        }
        if (xMax > finalXMaxValueMaxMeters) {
          error["xMaxInvalid"] =
            this.getErrorMessageValueShouldBeLessOrEqualThan(
              finalXMaxValueMaxMeters
            );
        }
        if (yMax > finalYMaxValueMaxMeters) {
          error["yMaxInvalid"] =
            this.getErrorMessageValueShouldBeLessOrEqualThan(
              finalYMaxValueMaxMeters
            );
        }
        if (sensorMounting !== "wall") {
          const finalYMinValueMinMeters =
            ROOM_LIMITS[sensorMounting].YMinValueMin.meters -
            canExceedDistanceMeters;
          if (yMin < finalYMinValueMinMeters) {
            error["yMinInvalid"] =
              this.getErrorMessageValueShouldBeGreaterOrEqualThan(
                finalYMinValueMinMeters
              );
          }
        }
      } // feet validation
      else {
        const yMinFeet = group.get("yMinFeet").value;
        const yMinInches = group.get("yMinInches").value;
        const yMaxFeet = group.get("yMaxFeet").value;
        const yMaxInches = group.get("yMaxInches").value;
        const xMinFeet = group.get("xMinFeet").value;
        const xMinInches = group.get("xMinInches").value;
        const xMaxFeet = group.get("xMaxFeet").value;
        const xMaxInches = group.get("xMaxInches").value;

        const finalYMaxValueMax = {
          ...ROOM_LIMITS[sensorMounting].YMaxValueMax,
        };
        const finalXMinValueMin = {
          ...ROOM_LIMITS[sensorMounting].XMinValueMin,
        };
        const finalXMaxValueMax = {
          ...ROOM_LIMITS[sensorMounting].XMaxValueMax,
        };
        if (this.enableDoorEvents && group.get("isDoor").value) {
          finalYMaxValueMax.meters += DOOR_THICKNESS_IN_METERS;
          const yMaxMaxFeets = convertToFeetSystem(finalYMaxValueMax.meters);
          finalYMaxValueMax.feet = yMaxMaxFeets.feet;
          finalYMaxValueMax.inches = yMaxMaxFeets.inches;

          finalXMinValueMin.meters -= DOOR_THICKNESS_IN_METERS;
          const xMinMinfeets = convertToFeetSystem(finalXMinValueMin.meters);
          finalXMinValueMin.feet = xMinMinfeets.feet;
          finalXMinValueMin.inches = xMinMinfeets.inches;

          finalXMaxValueMax.meters += DOOR_THICKNESS_IN_METERS;
          const xMaxMaxfeets = convertToFeetSystem(finalXMaxValueMax.meters);
          finalXMaxValueMax.feet = xMaxMaxfeets.feet;
          finalXMaxValueMax.inches = xMaxMaxfeets.inches;
        }
        if (
          !isFeetValueLessOrEqualThan(
            yMaxFeet,
            finalYMaxValueMax.feet,
            yMaxInches,
            finalYMaxValueMax.inches
          )
        ) {
          error["yMaxInvalid"] =
            this.getErrorMessageValueShouldBeLessOrEqualThan(
              `${finalYMaxValueMax.feet}'${finalYMaxValueMax.inches}"`
            );
        }

        if (
          !isFeetValueGreaterOrEqualThan(
            xMinFeet,
            finalXMinValueMin.feet,
            xMinInches,
            finalXMinValueMin.inches
          )
        ) {
          error["xMinInvalid"] =
            this.getErrorMessageValueShouldBeGreaterOrEqualThan(
              `${finalXMinValueMin.feet}'${finalXMinValueMin.inches}"`
            );
        }
        if (
          !isFeetValueLessOrEqualThan(
            xMaxFeet,
            finalXMaxValueMax.feet,
            xMaxInches,
            finalXMaxValueMax.inches
          )
        ) {
          error["xMaxInvalid"] =
            this.getErrorMessageValueShouldBeLessOrEqualThan(
              `${finalXMaxValueMax.feet}'${finalXMaxValueMax.inches}"`
            );
        }
        if (sensorMounting !== "wall") {
          const finalYMinValueMin = {
            ...ROOM_LIMITS[sensorMounting].YMinValueMin,
          };
          if (this.enableDoorEvents && group.get("isDoor").value) {
            finalYMinValueMin.meters -= DOOR_THICKNESS_IN_METERS;
            const yMinMinFeets = convertToFeetSystem(finalYMinValueMin.meters);
            finalYMinValueMin.feet = yMinMinFeets.feet;
            finalYMinValueMin.inches = yMinMinFeets.inches;
          }
          if (
            !isFeetValueGreaterOrEqualThan(
              yMinFeet,
              finalYMinValueMin.feet,
              yMinInches,
              finalYMinValueMin.inches
            )
          ) {
            error["yMinInvalid"] =
              this.getErrorMessageValueShouldBeGreaterOrEqualThan(
                `${finalYMinValueMin.feet}'${finalYMinValueMin.inches}"`
              );
          }
        }
      }
      return error;
    };
  }
  /**
   * Checks that the value of the start point must always be smaller than the value of the end point and
   * the value of the end point  must always be bigger than the value of the start point.
   * For example x1 < x2 or x2 > x1
   */
  get subregionMinMaxValidator(): ValidatorFn {
    return (group: FormGroup<SubregionForm>) => {
      const error = {};
      let xMin, xMax, yMin, yMax, zMin, zMax;
      const xMinUpdateTimestamp = this.controlLastUpdateTimestamp["xMin"],
        xMaxUpdateTimestamp = this.controlLastUpdateTimestamp["xMax"],
        yMinUpdateTimestamp = this.controlLastUpdateTimestamp["yMin"],
        yMaxUpdateTimestamp = this.controlLastUpdateTimestamp["yMax"],
        zMinUpdateTimestamp = this.controlLastUpdateTimestamp["zMin"],
        zMaxUpdateTimestamp = this.controlLastUpdateTimestamp["zMax"];
      if (this.isMetric()) {
        xMin = group.get("xMin").value;
        xMax = group.get("xMax").value;
        yMin = group.get("yMin").value;
        yMax = group.get("yMax").value;
        zMin = group.get("zMin").value;
        zMax = group.get("zMax").value;
      } else {
        xMin = convertFeetAndInchesToMeters(
          group.get("xMinFeet").value,
          group.get("xMinInches").value
        );
        xMax = convertFeetAndInchesToMeters(
          group.get("xMaxFeet").value,
          group.get("xMaxInches").value
        );
        yMin = convertFeetAndInchesToMeters(
          group.get("yMinFeet").value,
          group.get("yMinInches").value
        );
        yMax = convertFeetAndInchesToMeters(
          group.get("yMaxFeet").value,
          group.get("yMaxInches").value
        );
        zMin = convertFeetAndInchesToMeters(
          group.get("zMinFeet").value,
          group.get("zMinInches").value
        );
        zMax = convertFeetAndInchesToMeters(
          group.get("zMaxFeet").value,
          group.get("zMaxInches").value
        );
      }
      if (xMinUpdateTimestamp > xMaxUpdateTimestamp && xMin >= xMax) {
        error[
          "xMinValueInvalid"
        ] = $localize`:@@x-min-ceiling-value-invalid:xMin should be < xMax`;
      }
      if (xMaxUpdateTimestamp > xMinUpdateTimestamp && xMax <= xMin) {
        error[
          "xMaxValueInvalid"
        ] = $localize`:@@x-max-ceiling-value-invalid:xMax should be > xMin`;
      }
      if (yMinUpdateTimestamp > yMaxUpdateTimestamp && yMin >= yMax) {
        error[
          "yMinValueInvalid"
        ] = $localize`:@@y-min-ceiling-value-invalid:yMin should be < yMax`;
      }
      if (yMaxUpdateTimestamp > yMinUpdateTimestamp && yMax <= yMin) {
        error[
          "yMaxValueInvalid"
        ] = $localize`:@@y-max-ceiling-value-invalid:yMax should be > yMin`;
      }
      if (zMinUpdateTimestamp > zMaxUpdateTimestamp && zMin >= zMax) {
        error[
          "zMinValueInvalid"
        ] = $localize`:@@z-min-ceiling-value-invalid:zMin should be < zMax`;
      }
      if (zMaxUpdateTimestamp > zMinUpdateTimestamp && zMax <= zMin) {
        error[
          "zMaxValueInvalid"
        ] = $localize`:@@z-max-ceiling-value-invalid:zMax should be > zMin`;
      }

      return error;
    };
  }

  /**
   * Checks that the size of the subregion does not exceed the size of the room
   */
  get subregionContainedValidator(): ValidatorFn {
    return (group: FormGroup<RoomSizeForm>) => {
      const error = {};
      let xMin: number,
        xMax: number,
        yMin: number,
        yMax: number,
        zMin: number,
        zMax: number,
        xMinRoom: number,
        xMinRoomIsValid: boolean,
        xMaxRoom: number,
        xMaxRoomIsValid: boolean,
        yMaxRoom: number,
        yMaxRoomIsValid: boolean,
        yMinRoom: number,
        yMinRoomIsValid: boolean;
      if (this.isMetric()) {
        xMin = group.get("xMin").value;
        xMax = group.get("xMax").value;
        yMin = group.get("yMin").value;
        yMax = group.get("yMax").value;
        zMin = group.get("zMin").value;
        zMax = group.get("zMax").value;

        if (this.isWallMount) {
          /**
           * When the sensor is placed on the wall,
           * yMin are not editable by the user, so we take their value not from the form,
           * but from the response from the server.
           */
          yMinRoom = this.config.walabotConfig.yMin;
          yMinRoomIsValid = true;
        } else {
          yMinRoom = this.roomSize.get("roomYMin").value;
          yMinRoomIsValid = !this.roomSize.hasError("yMinInvalid");
        }
        xMinRoom = this.roomSize.get("roomXMin").value;
        xMaxRoom = this.roomSize.get("roomXMax").value;
        yMaxRoom = this.roomSize.get("roomYMax").value;

        xMinRoomIsValid = !this.roomSize.hasError("xMinInvalid");
        xMaxRoomIsValid = !this.roomSize.hasError("xMaxInvalid");
        yMaxRoomIsValid = !this.roomSize.hasError("yMaxInvalid");
      } else {
        xMin = convertFeetAndInchesToMeters(
          group.get("xMinFeet").value,
          group.get("xMinInches").value
        );
        xMax = convertFeetAndInchesToMeters(
          group.get("xMaxFeet").value,
          group.get("xMaxInches").value
        );
        yMin = convertFeetAndInchesToMeters(
          group.get("yMinFeet").value,
          group.get("yMinInches").value
        );
        yMax = convertFeetAndInchesToMeters(
          group.get("yMaxFeet").value,
          group.get("yMaxInches").value
        );
        zMin = convertFeetAndInchesToMeters(
          group.get("zMinFeet").value,
          group.get("zMinInches").value
        );
        zMax = convertFeetAndInchesToMeters(
          group.get("zMaxFeet").value,
          group.get("zMaxInches").value
        );

        if (this.isWallMount) {
          /**
           * When the sensor is placed on the wall,
           * yMin are not editable by the user, so we take their value not from the form,
           * but from the response from the server.
           */
          const yMinInFeet = convertMetersToFeet(
            this.config.walabotConfig.yMin
          );
          yMinRoom = convertFeetAndInchesToMeters(yMinInFeet[0], yMinInFeet[1]);
          yMinRoomIsValid = true;
        } else {
          yMinRoom = convertFeetAndInchesToMeters(
            this.roomSize.get("roomYMinFeet").value,
            this.roomSize.get("roomYMinInches").value
          );
          yMinRoomIsValid = !this.roomSize.hasError("yMinInvalid");
        }
        xMinRoom = convertFeetAndInchesToMeters(
          this.roomSize.get("roomXMinFeet").value,
          this.roomSize.get("roomXMinInches").value
        );
        xMaxRoom = convertFeetAndInchesToMeters(
          this.roomSize.get("roomXMaxFeet").value,
          this.roomSize.get("roomXMaxInches").value
        );
        yMaxRoom = convertFeetAndInchesToMeters(
          this.roomSize.get("roomYMaxFeet").value,
          this.roomSize.get("roomYMaxInches").value
        );

        xMinRoomIsValid = !this.roomSize.hasError("xMinInvalid");
        xMaxRoomIsValid = !this.roomSize.hasError("xMaxInvalid");
        yMaxRoomIsValid = !this.roomSize.hasError("yMaxInvalid");
      }

      /**
       * zMin and zMax are not editable by the user, so we take their value not from the form,
       * but from the response from the server.
       */
      const zMinRoom = this.config.walabotConfig.zMin;
      const zMaxRoom = this.config.walabotConfig.zMax;

      const canExceedDistance =
        this.enableDoorEvents && group.get("isDoor").value
          ? DOOR_THICKNESS_IN_METERS
          : 0;
      const finalXMinRoom = xMinRoom - canExceedDistance;
      const finalXMaxRoom = xMaxRoom + canExceedDistance;
      const finalYMinRoom = yMinRoom - canExceedDistance;
      const finalYMaxRoom = yMaxRoom + canExceedDistance;

      if (xMinRoomIsValid && xMin < finalXMinRoom) {
        const roomValue = this.prepareValueForErrorMessage(finalXMinRoom);
        error[
          "xMinValueNotContainedInRoom"
        ] = $localize`:@@value-should-be-greater-or-equal-to-room:Value should be >= ${roomValue}:roomValue:`;
      }
      if (xMaxRoomIsValid && xMax > finalXMaxRoom) {
        const roomValue = this.prepareValueForErrorMessage(finalXMaxRoom);
        error[
          "xMaxValueNotContainedInRoom"
        ] = $localize`:@@value-should-be-less-or-equal-to-room:Value should be <= ${roomValue}:roomValue:`;
      }
      if (yMinRoomIsValid && yMin < finalYMinRoom) {
        const roomValue = this.prepareValueForErrorMessage(finalYMinRoom);
        error[
          "yMinValueNotContainedInRoom"
        ] = $localize`:@@value-should-be-greater-or-equal-to-room:Value should be >= ${roomValue}:roomValue:`;
      }
      if (yMaxRoomIsValid && yMax > finalYMaxRoom) {
        const roomValue = this.prepareValueForErrorMessage(finalYMaxRoom);
        error[
          "yMaxValueNotContainedInRoom"
        ] = $localize`:@@value-should-be-less-or-equal-to-room:Value should be <= ${roomValue}:roomValue:`;
      }
      if (zMin < zMinRoom) {
        const roomValue = this.prepareValueForErrorMessage(zMinRoom);
        error[
          "zMinValueNotContainedInRoom"
        ] = $localize`:@@value-should-be-greater-or-equal:Value should be >= ${roomValue}:min:`;
      }
      if (zMax > zMaxRoom) {
        const roomValue = this.prepareValueForErrorMessage(zMaxRoom);
        error[
          "zMaxValueNotContainedInRoom"
        ] = $localize`:@@value-should-be-less-or-equal:Value should be <= ${roomValue}:max:`;
      }
      return error;
    };
  }

  get subregionInchesValidator(): ValidatorFn {
    return (group: FormGroup<SubregionForm>) => {
      const error = {};
      let xMinInches,
        xMaxInches,
        yMinInches,
        yMaxInches,
        zMinInches,
        zMaxInches;

      if (!this.isMetric()) {
        xMinInches = group.get("xMinInches").value;
        xMaxInches = group.get("xMaxInches").value;
        yMinInches = group.get("yMinInches").value;
        yMaxInches = group.get("yMaxInches").value;
        zMinInches = group.get("zMinInches").value;
        zMaxInches = group.get("zMaxInches").value;

        // inches < MAX_VALUE_INCHES validation
        const inchesInvalidErrorMax = `inches < ${MAX_VALUE_INCHES}`;
        const inchesInvalidErrorMin = `inches > -${MAX_VALUE_INCHES}`;
        if (xMinInches >= MAX_VALUE_INCHES) {
          error["xMinInchesInvalid"] = inchesInvalidErrorMax;
        }
        if (xMinInches <= -MAX_VALUE_INCHES) {
          error["xMinInchesInvalid"] = inchesInvalidErrorMin;
        }
        if (xMaxInches >= MAX_VALUE_INCHES) {
          error["xMaxInchesInvalid"] = inchesInvalidErrorMax;
        }
        if (xMaxInches <= -MAX_VALUE_INCHES) {
          error["xMaxInchesInvalid"] = inchesInvalidErrorMin;
        }
        if (yMinInches >= MAX_VALUE_INCHES) {
          error["yMinInchesInvalid"] = inchesInvalidErrorMax;
        }
        if (yMinInches <= -MAX_VALUE_INCHES) {
          error["yMinInchesInvalid"] = inchesInvalidErrorMin;
        }
        if (yMaxInches >= MAX_VALUE_INCHES) {
          error["yMaxInchesInvalid"] = inchesInvalidErrorMax;
        }
        if (yMaxInches <= -MAX_VALUE_INCHES) {
          error["yMaxInchesInvalid"] = inchesInvalidErrorMin;
        }
        if (zMinInches >= MAX_VALUE_INCHES) {
          error["zMinInchesInvalid"] = inchesInvalidErrorMax;
        }
        if (zMinInches <= -MAX_VALUE_INCHES) {
          error["zMinInchesInvalid"] = inchesInvalidErrorMin;
        }
        if (zMaxInches >= MAX_VALUE_INCHES) {
          error["zMaxInchesInvalid"] = inchesInvalidErrorMax;
        }
        if (zMaxInches <= -MAX_VALUE_INCHES) {
          error["zMaxInchesInvalid"] = inchesInvalidErrorMin;
        }
      }

      return error;
    };
  }

  constructor(private fb: UntypedFormBuilder) {
    super();
  }

  ngOnInit(): void {
    this.setFormData();

    this.subRegions.valueChanges
      .pipe(
        startWith(this.subRegions.value),
        pairwise(),
        takeUntil(this.ngUnsubsrcibe)
      )
      .subscribe(
        ([previousValues, currentValues]: [
          Array<TrackerSubRegion & { bedSettings: boolean }>,
          Array<TrackerSubRegion & { bedSettings: boolean }>
        ]) => {
          currentValues.forEach((currentValue, index) => {
            const previousValue = previousValues[index];
            if (
              JSON.stringify(currentValue) !== JSON.stringify(previousValue)
            ) {
              if (previousValue?.bedSettings === currentValue.bedSettings) {
                this.updateBedSettings(index);
              }
              if (previousValue?.isDoor === currentValue.isDoor) {
                this.updateDoorSettings(index);
              }
            }
          });
        }
      );
  }

  ngOnChanges(c: SimpleChanges): void {
    if (this.subRegions && ("unit" in c || "config" in c)) {
      this.setFormData();
    }
  }

  setFormData() {
    this.subRegions.clear();
    if (this.config.walabotConfig.trackerSubRegions) {
      this.config.walabotConfig.trackerSubRegions.forEach((subRegion) => {
        this.addSubRegion(subRegion);
      });
    }
    this.selectedSubRegionIndex = null;
  }

  validateRoomSizeAgainstSubregionChanged(controlName: string) {
    this.subregionChanged.emit(controlName);
  }

  updateLastUpdateTimestamp(controlName: string) {
    this.updateTimestamp.emit(controlName);
  }

  getSubregionErrorMessage(index: number, field: string) {
    const subregion = this.subRegions.at(index);
    return (subregion.getError(`${field}ValueNotContainedInRoom`) ||
      subregion.getError(`${field}Invalid`) ||
      subregion.getError(`${field}ValueInvalid`)) as string;
  }

  getSubregionInchesErrorMessage(index: number, field: string) {
    const subregion = this.subRegions.at(index);
    return subregion.getError(field + "InchesInvalid") as string;
  }

  getDurationErrorMessage(form: FormGroup, field: string) {
    return getDurationErrorMessage(form, field, MIN_DURATION_VALUE);
  }

  filterNegativeValueInches(
    event: KeyboardEvent,
    feetName: string,
    subRegionIndex: number
  ) {
    const feetControl = this.subRegions.at(subRegionIndex).get(feetName);
    return filterNegativeValueInches(event, feetControl.value as number);
  }

  filterNegativeValueFeet(
    event: KeyboardEvent,
    InchesName: string,
    subRegionIndex: number
  ) {
    const inchesControl = this.subRegions.at(subRegionIndex).get(InchesName);
    return filterNegativeValueFeet(event, inchesControl.value as number);
  }

  addSubRegion(region?: TrackerSubRegion) {
    const roomSize = this.getRoomSize();
    const sensorHeight = this.getSensorHeight();
    const roomType = this.deviceSettings.get("roomType").value as RoomType;
    const controlConfig = {
      name: [
        region && region.name
          ? region.name
          : `SubRegion${this.subRegions.length}`,
        required,
      ],
      isFallingDetection: [
        region && region.isFallingDetection != null
          ? region.isFallingDetection
          : false,
      ],
      isPresenceDetection: [
        region && region.isPresenceDetection != null
          ? region.isPresenceDetection
          : true,
      ],
      enterDuration: [
        region && region.enterDuration != null
          ? region.enterDuration
          : DEFAULT_SUB_REGION.enterDuration,
        [required, Validators.min(0)],
      ],
      exitDuration: [
        region && region.exitDuration != null
          ? region.exitDuration
          : DEFAULT_SUB_REGION.exitDuration,
        [required, Validators.min(0)],
      ],
      isLowSnr: [region?.isLowSnr ?? roomType !== RoomType.Bathroom],
      isHorizontal: [region?.isHorizontal ?? false],
      isDoor: [region?.isDoor ?? false],
      bedSettings: [
        (region?.isFallingDetection === BED_SETTINGS.isFallingDetection &&
          region?.isPresenceDetection === BED_SETTINGS.isPresenceDetection &&
          region?.isLowSnr === BED_SETTINGS.isLowSnr) ??
          false,
      ],
    };
    let validators = [];

    if (this.isMetric()) {
      Object.assign(controlConfig, {
        xMin: [
          region && region.xMin != null
            ? region.xMin
            : Math.max(DEFAULT_SUB_REGION.xMin.meters, roomSize.xMin),
          required,
        ],
        xMax: [
          region && region.xMax != null
            ? region.xMax
            : Math.min(DEFAULT_SUB_REGION.xMax.meters, roomSize.xMax),
          required,
        ],
        yMin: [
          region && region.yMin != null
            ? region.yMin
            : Math.max(DEFAULT_SUB_REGION.yMin.meters, roomSize.yMin),
          required,
        ],
        yMax: [
          region && region.yMax != null
            ? region.yMax
            : Math.min(DEFAULT_SUB_REGION.yMax.meters, roomSize.yMax),
          required,
        ],
        zMin: [
          region && region.zMin != null
            ? region.zMin
            : DEFAULT_SUB_REGION.zMin.meters,
          required,
        ],
        zMax: [
          region && region.zMax != null
            ? region.zMax
            : Math.min(DEFAULT_SUB_REGION.zMax.meters, sensorHeight),
          required,
        ],
      });
      validators = [
        this.subregionMinMaxValidator,
        this.subregionContainedValidator,
        this.subregionSizeValidator,
      ];
    }
    // feet
    else {
      const xMin =
        region && region.xMin != null
          ? convertMetersToFeet(region.xMin)
          : [
              ...convertMetersToFeet(
                Math.max(
                  convertFeetAndInchesToMeters(
                    DEFAULT_SUB_REGION.xMin.feet,
                    DEFAULT_SUB_REGION.xMin.inches
                  ),
                  roomSize.xMin
                )
              ),
            ];
      const xMinFeet = modifyNegativeZeroForFeet(xMin[0]);
      const xMinInches = xMin[1];

      const xMax =
        region && region.xMax != null
          ? convertMetersToFeet(region.xMax)
          : [
              ...convertMetersToFeet(
                Math.min(
                  convertFeetAndInchesToMeters(
                    DEFAULT_SUB_REGION.xMax.feet,
                    DEFAULT_SUB_REGION.xMax.inches
                  ),
                  roomSize.xMax
                )
              ),
            ];
      const xMaxFeet = modifyNegativeZeroForFeet(xMax[0]);
      const xMaxInches = xMax[1];

      const yMin =
        region && region.yMin != null
          ? convertMetersToFeet(region.yMin)
          : [
              ...convertMetersToFeet(
                Math.max(
                  convertFeetAndInchesToMeters(
                    DEFAULT_SUB_REGION.yMin.feet,
                    DEFAULT_SUB_REGION.yMin.inches
                  ),
                  roomSize.yMin
                )
              ),
            ];
      const yMinFeet = modifyNegativeZeroForFeet(yMin[0]);
      const yMinInches = yMin[1];

      const yMax =
        region && region.yMax != null
          ? convertMetersToFeet(region.yMax)
          : [
              ...convertMetersToFeet(
                Math.min(
                  convertFeetAndInchesToMeters(
                    DEFAULT_SUB_REGION.yMax.feet,
                    DEFAULT_SUB_REGION.yMax.inches
                  ),
                  roomSize.yMax
                )
              ),
            ];
      const yMaxFeet = modifyNegativeZeroForFeet(yMax[0]);
      const yMaxInches = yMax[1];

      const zMin =
        region && region.zMin != null
          ? convertMetersToFeet(region.zMin)
          : [DEFAULT_SUB_REGION.zMin.feet, DEFAULT_SUB_REGION.zMin.inches];
      const zMinFeet = modifyNegativeZeroForFeet(zMin[0]);
      const zMinInches = zMin[1];

      const zMax =
        region && region.zMax != null
          ? convertMetersToFeet(region.zMax)
          : [
              ...convertMetersToFeet(
                Math.min(
                  convertFeetAndInchesToMeters(
                    DEFAULT_SUB_REGION.zMax.feet,
                    DEFAULT_SUB_REGION.zMax.inches
                  ),
                  sensorHeight
                )
              ),
            ];
      const zMaxFeet = modifyNegativeZeroForFeet(zMax[0]);
      const zMaxInches = zMax[1];

      Object.assign(controlConfig, {
        xMinFeet: [xMinFeet, required],
        xMinInches: [xMinInches, required],
        xMaxFeet: [xMaxFeet, required],
        xMaxInches: [xMaxInches, required],
        yMinFeet: [yMinFeet, required],
        yMinInches: [yMinInches, required],
        yMaxFeet: [yMaxFeet, required],
        yMaxInches: [yMaxInches, required],
        zMinFeet: [zMinFeet, required],
        zMinInches: [zMinInches, required],
        zMaxFeet: [zMaxFeet, required],
        zMaxInches: [zMaxInches, required],
      });
      validators = [
        this.subregionMinMaxValidator,
        this.subregionContainedValidator,
        this.subregionInchesValidator,
        this.subregionSizeValidator,
      ];
    }
    this.subRegions.push(this.fb.group(controlConfig, { validators }));
    if (!region) {
      this.subRegions.markAsDirty();
    }
    this.selectedSubRegionIndex = this.subRegions.length - 1;
  }

  drawNewSubRegion() {
    this.drawSubRegion.emit();
  }

  removeSubRegion(index: number) {
    this.subRegions.removeAt(index);
    this.subRegions.markAsDirty();
  }

  getErrorMessageValueShouldBeLessOrEqualThan(max) {
    return $localize`:@@value-should-be-less-or-equal:Value should be <= ${max}:max:`;
  }

  getErrorMessageValueShouldBeGreaterOrEqualThan(min) {
    return $localize`:@@value-should-be-greater-or-equal:Value should be >= ${min}:min:`;
  }

  isMetric() {
    return this.unit === MeasurementUnits.Metric;
  }

  goToNextTabIndex(tabGroup: MatTabGroup) {
    if (!tabGroup || !(tabGroup instanceof MatTabGroup)) return;
    const tabCount = tabGroup._tabs.length;
    if (tabGroup.selectedIndex + 1 < tabCount) {
      tabGroup.selectedIndex = tabGroup.selectedIndex + 1;
    }
  }

  goToPrevTabIndex(tabGroup: MatTabGroup) {
    if (!tabGroup || !(tabGroup instanceof MatTabGroup)) return;
    if (tabGroup.selectedIndex > 0) {
      tabGroup.selectedIndex = tabGroup.selectedIndex - 1;
    }
  }

  private prepareValueForErrorMessage(value: number) {
    if (this.isMetric()) {
      return value;
    } else {
      const feetSystem = convertMetersToFeet(value);
      return `${feetSystem[0]}'${feetSystem[1]}"`;
    }
  }

  reorder(event: CdkDragDrop<string[]>) {
    moveItemInArray(
      this.subRegions.controls,
      event.previousIndex,
      event.currentIndex
    );
    this.subRegions.markAsDirty();
  }

  onBedSettingsChange(e: MatSlideToggleChange, index: number) {
    if (e.checked) {
      this.subRegions.at(index).patchValue(BED_SETTINGS);
    }
  }

  onDoorSettingsChange(e: MatSlideToggleChange, index: number) {
    if (e.checked) {
      this.subRegions.at(index).patchValue(DOOR_SETTINGS);
    }
  }

  updateBedSettings(index: number) {
    this.subRegions.at(index).patchValue({
      bedSettings: Object.keys(BED_SETTINGS).every(
        (key) => this.subRegions.at(index).get(key).value === BED_SETTINGS[key]
      ),
    });
  }

  updateDoorSettings(index: number) {
    if (
      !Object.keys(DOOR_SETTINGS)
        .filter((key) => key !== "isDoor")
        .every(
          (key) =>
            this.subRegions.at(index).get(key).value === DOOR_SETTINGS[key]
        )
    ) {
      this.subRegions.at(index).patchValue({
        isDoor: false,
      });
    }
  }
}
