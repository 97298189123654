<div class="container page">
    <ng-container *ngIf="isSuiteView">
        <h1 i18n="@@resident-dashboard">Resident Dashboard</h1>
        <span class="facility-and-apartment-name">
            <a routerLink="/{{basePath}}/insights" class="facility-name">{{environment.presentUserNameAndEmail ? getUserNameAndEmail(user).displayName : demoUser}}</a>
             / Apartment {{deviceMonitoringService.suiteStatusMap[suiteId]?.name}}
            <span class="devices-number">({{suiteDevicesNumber}} <span i18n="@@devices">Devices</span>)</span>
        </span>
        <a routerLink="/{{basePath}}/insights" class="back"><img src="assets/icons/back.svg"> Back</a>
    </ng-container>
    <ng-container *ngIf="!isSuiteView">
        <h1 i18n="@@community-dashboard">Community Dashboard</h1>
        <span class="facility-name">{{environment.presentUserNameAndEmail ? getUserNameAndEmail(user).displayName : demoUser}} <span class="apartment-number">({{suitesNumber}} <span i18n="@@apartments">Apartments</span>)</span></span>
    </ng-container>
    <button *ngIf="isAdminDashboardUser" mat-raised-button class="upload-csv" (click)="csv.click()"><ng-container i18n="@@upload-csv">Upload CSV</ng-container><input type="file" #csv accept="text/csv" (change)="uploadCSV($event)"></button>
    <span class="datetime daily">{{analytics?.dailyStartTime | date:'mediumDate'}} - {{analytics?.dailyEndTime | date:'mediumDate'}} (<b i18n="@@daily-update">Daily Update</b>)</span>
    <div class="row daily">
        <div class="slot" style="flex-direction: row; height: 120px;">
            <div class="slot-fall-title">
                <div style="display: flex; align-items: center; gap: 7px;">
                    <img src="assets/icons/fall.png">
                    <div style="display: flex; flex-direction: column;">
                        <span class="falls" i18n="@@4-weeks">4 Weeks</span>
                        <span class="falls" i18n="@@fall-summary">Fall Summary</span>
                    </div>
                </div>
            </div>
            <div class="slot with-E8F3F6-background with-left-border-radius">
                <h4 i18n="@@total-confirmed-falls">Total Confirmed Falls</h4>
                <span *ngIf="!isSuiteView" class="value">
                    <span>{{analytics?.falls.total.value}} <span i18n="@@falls">Falls</span>/</span>
                    <span class="sub-value">{{analytics?.falls.injurySuites.value}} <span i18n="@@fallers">Fallers</span></span>
                </span>
                <span *ngIf="isSuiteView" class="value">{{analytics?.falls.total.value}}</span>
                <div class="delta" [style.marginLeft.px]="isSuiteView ? 0 : -90">
                    <ng-container *ngTemplateOutlet="delta; context: {delta: analytics?.falls.total.delta, value: analytics?.falls.total.value, previousValue: analytics?.falls.total.previousValue}"></ng-container>
                </div>
            </div>
            <div class="slot width-86 with-left-border  with-E8F3F6-background">
                <h4 i18n="@@with-injuries">With Injuries</h4>
                <span class="value">{{analytics?.falls.withInjuries.value}}</span>
                <div class="delta">
                    <ng-container *ngTemplateOutlet="delta; context: {delta: analytics?.falls.withInjuries.delta, value: analytics?.falls.withInjuries.value, previousValue: analytics?.falls.withInjuries.previousValue}"></ng-container>
                </div>
            </div>
            <div class="slot width-86 with-left-border with-E8F3F6-background with-right-border-radius">
                <h4 i18n="@@without-injuries">Without Injuries</h4>
                <span class="value">{{analytics?.falls.withoutInjuries.value}}</span>
                <div class="delta">
                    <ng-container *ngTemplateOutlet="delta; context: {delta: analytics?.falls.withoutInjuries.delta, value: analytics?.falls.withoutInjuries.value, previousValue: analytics?.falls.withoutInjuries.previousValue}"></ng-container>
                </div>
            </div>
            <div class="slot width-80">
                <h4 i18n="@@unresolved">Unresolved</h4>
                <span class="value">{{analytics?.falls.unresolved.value}} <img *ngIf="(analytics?.falls.unresolved.value/(analytics?.falls.unresolved.value + analytics?.falls.total.value)) * 100 > unresolvedFallsWarningLevel" src="assets/icons/warning.svg" class="warning"><div i18n="@@unresolved-falls-warning" class="warning-popup">Data is non-applicable. Please check with the supervisor.</div></span>
                <div class="delta">
                    <ng-container *ngTemplateOutlet="delta; context: {delta: analytics?.falls.unresolved.delta, value: analytics?.falls.unresolved.value, previousValue: analytics?.falls.unresolved.previousValue}"></ng-container>
                </div>
            </div>
        </div>
    </div>
    <span class="datetime weekly">{{analytics?.startTime | date: 'mediumDate'}} - {{analytics?.endTime | date: 'mediumDate'}} (<b i18n="@@weekly-update">Weekly Update</b>)</span>
    <div class="row weekly">
        <div *ngIf="!isSuiteView; else potentialFalls" class="sub-row" style="flex-direction: column;">
            <div class="sub-row">
                <div class="slot" data-cy="newFallers">
            <span class="cdk-visually-hidden" #newFallersTooltip i18n="@@new-fallers-tooltip">
                Number of residents that had at least one fall in the past 4 weeks that did not have any falls in the previous 4 weeks.
            </span>
                    <h3><ng-container i18n="@@new-fallers">New Fallers</ng-container> <img src="assets/icons/info.svg"
                                                                                           [matTooltip]="newFallersTooltip.innerHTML" matTooltipClass="tooltip" matTooltipPosition="right"></h3>
                    <span class="value">{{analytics?.newFallers.value ?? '-'}}</span>
                    <div class="delta">
                        <ng-container *ngTemplateOutlet="delta; context: {delta: analytics?.newFallers.delta, value: analytics?.newFallers.value, previousValue: analytics?.newFallers.previousValue}"></ng-container>
                    </div>
                    <a class="show-new-fallers" (click)="showNewFallers()"><img src="assets/icons/pager-arrow.svg"></a>
                </div>
                <ng-container *ngTemplateOutlet="potentialFalls"></ng-container>
            </div>
            <div class="sub-row">
                <div class="slot" data-cy="highRiskResidents">
                    <span class="cdk-visually-hidden" #highRiskResidentsTooltip i18n="@@high-risk-residents-tooltip">
                        Total Number of residents with potential high fall risk. Potential Fall Risk is calculated by a proprietary algorithm that takes into account fall history, frequency, occurrence patterns, and caregiver resolutions.
                    </span>
                    <h3><ng-container i18n="@@high-risk-residents">High Risk Residents</ng-container> <img src="assets/icons/info.svg"
                                                                                                           [matTooltip]="highRiskResidentsTooltip.innerHTML" matTooltipClass="tooltip" matTooltipPosition="right"></h3>
                    <div class="stack" style="margin: auto;">
                        <span class="value">{{analytics?.highRiskResidents.value ?? '-'}}</span>
                        <div class="delta">
                            <ng-container *ngTemplateOutlet="delta; context: {delta: analytics?.highRiskResidents.delta, value: analytics?.highRiskResidents.value, previousValue: analytics?.highRiskResidents.previousValue}"></ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="sub-row" style="flex-direction: column;">
            <div *ngIf="isSuiteView" class="slot" data-cy="fallRisk">
                    <span class="cdk-visually-hidden" #fallRiskTooltip i18n="@@fall-risk-tooltip">
                        Potential Fall Risk is calculated by a proprietary algorithm that takes into account fall history, frequency, occurrence patterns, and caregiver resolutions.
                    </span>
                <h3><ng-container i18n="@@fall-risk">Fall Risk</ng-container> <img src="assets/icons/info.svg"
                                                                                   [matTooltip]="fallRiskTooltip.innerHTML" matTooltipClass="tooltip" matTooltipPosition="right"></h3>
                <div class="stack" style="margin: auto;">
                    <span class="value" [ngStyle]="{color: getRiskLevelColor(analytics?.risk_level_id)}">{{analytics?.fallRisk.value ?? '-'}}</span>
                    <div class="delta">
                        <ng-container *ngTemplateOutlet="delta; context: {delta: analytics?.fallRisk.delta, value: analytics?.fallRisk.value, previousValue: analytics?.fallRisk.previousValue}"></ng-container>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="!isSuiteView" class="slot" data-cy="topRiskResidents">
            <div id="top-fall-ris-residents-tooltip" #topFallRiskResidentsTooltip class="mdc-tooltip mdc-tooltip--rich tooltip" aria-hidden="true" role="dialog">
                <div class="mdc-tooltip__surface mdc-tooltip__surface-animation">
                    <span i18n="@@top-fall-risk-residents-tooltip">
                        Top 5 residents with the highest estimated fall risk score in the community. Estimated fall risk is calculated by a proprietary algorithm that takes into account fall history, frequency, occurrence patterns, and caregiver resolutions.
                    </span>
                     <a target="_blank" href="assets/pdf/Vayyar.Care.Fall.Risk.Formula.and.Insights.pdf" class="mdc-tooltip__content-link"><span i18n="@@more-info">More Info</span> ></a>
                </div>
             </div>
            <h3 class="left-aligned">{{topFallRiskResidentsTitle}} <img src="assets/icons/info.svg" aria-describedby="top-fall-ris-residents-tooltip"></h3>
            <div class="chips">
                <div *ngFor="let level of analytics?.dict.risk_level" class="chip" [ngStyle]="{backgroundColor: '#' + level.color}">
                    {{level.name}}
                </div>
            </div>
            <table mat-table [dataSource]="analytics?.topRiskResidents | slice: 0 : 5" style="margin-top: 8px;">
                <ng-container matColumnDef="suite">
                    <th mat-header-cell *matHeaderCellDef i18n="@@apartment">Apartment</th>
                    <td mat-cell *matCellDef="let element" [ngStyle]="{color: getRiskLevelColor(element.risk_level_id)}" class="risk-score-value">
                        {{element.suite_name}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="score">
                    <th mat-header-cell *matHeaderCellDef i18n="@@risk-level">Risk Level</th>
                    <td mat-cell *matCellDef="let element" [ngStyle]="{color: getRiskLevelColor(element.risk_level_id)}" class="risk-score-value">
                        {{element.risk_score}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="link">
                    <th mat-header-cell *matHeaderCellDef i18n="@@apartment-view-link">Apartment View Link</th>
                    <td mat-cell *matCellDef="let element">
                        <a routerLink="/{{basePath}}/insights/{{element.suite_id}}">></a>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="topRiskResidentsDisplayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: topRiskResidentsDisplayedColumns;"></tr>
                <tr *matNoDataRow><td [attr.colspan]="topRiskResidentsDisplayedColumns.length" style="text-align: center">-</td></tr>
            </table>
            <a class="show-all" (click)="showRiskResidents()">Show all ></a>
        </div>
        <div class="slot" style="width: 364px; height: 220px;">
            <h3 class="left-aligned" i18n="@@average-fall-risk-trend">Average Fall Risk Trend</h3>
            <div class="chips">
                <div *ngFor="let level of analytics?.dict.risk_level" class="chip" [ngStyle]="{backgroundColor: '#' + level.color}">
                    {{level.name}}
                </div>
            </div>
            <app-chart *ngIf="analytics" type="line" [data]="analytics.riskTrend" [yMin]="0" [yMax]="100"
            [risk_level]="analytics?.dict.risk_level"></app-chart>
        </div>
        <div *ngIf="isSuiteView" class="slot" style="min-width: 500px; height: 220px;" data-cy="fallsHistory">
            <h3 i18n="@@falls-past-6-months" class="left-aligned">Resident Fall History - Past 6 Months</h3>
            <div style="width: 100%; height: 100%; overflow: auto;margin-top: 10px;">
                <table mat-table [dataSource]="analytics?.fallsHistory" class="falls-history">
                    <ng-container matColumnDef="date">
                        <th mat-header-cell *matHeaderCellDef i18n="@@time-and-date">Time & Date</th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.fall_time | date: dateTimeFormat }} {{zoneAbbr}}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="diff">
                        <th mat-header-cell *matHeaderCellDef i18n="@@time-since-last-fall">Time since last fall</th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.diff }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="room">
                        <th mat-header-cell *matHeaderCellDef i18n="@@room">Room</th>
                        <td mat-cell *matCellDef="let element">
                            {{element.room}}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="resolution">
                        <th mat-header-cell *matHeaderCellDef i18n="@@resolution">Resolution</th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.resolution }}
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="fallsHistoryDisplayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: fallsHistoryDisplayedColumns;"></tr>
                    <tr *matNoDataRow><td [attr.colspan]="fallsHistoryDisplayedColumns.length" class="no-confirmed-falls-history" i18n="@@no-confirmed-falls-history">No confirmed falls in the last 6 months</td></tr>
                </table>
            </div>
        </div>
        <div class="break"></div>
        <ng-container *ngIf="isSuiteView">
            <div style="height: 164px; min-width: 400px;" class="slot">
                <h3 class="left-aligned">Average Presence <span class="in-hours">(<ng-container i18n="@@in-hours">In Hours</ng-container>)</span></h3>
                <ng-container *ngIf="analytics?.averagePresence.is_presence_data_valid !== false; else notEnoughData">
                    <app-chart *ngIf="analytics?.averagePresence; else noData" type="doughnut" [data]="averagePresenceChartData"></app-chart>
                </ng-container>
            </div>
            <div *ngIf="hasBathroom" class="slot auto-width delta-slot">
                <h3 class="left-aligned">Change in Time in<br>Bathroom</h3>
                <div class="slot time-change-delta">
                    <ng-container *ngIf="analytics?.averagePresence.is_presence_data_valid !== false; else notEnoughData">
                        <ng-container *ngIf="isNumber(analytics?.inBathroomDelta); else noData">
                            <div *ngIf="analytics?.inBathroomDelta; else noChange">
                                <div>
                                    <img class="room-type" src="assets/icons/room/delta-bathroom.svg">
                                    <ng-container *ngTemplateOutlet="deltaIcon; context: {value: analytics?.inBathroomDelta}"></ng-container>
                                    <span class="value">
                                    <ng-container *ngIf="Math.abs(analytics?.inBathroomDelta) > 500; else value">>500%</ng-container>
                                    <ng-template #value>{{Math.abs(analytics?.inBathroomDelta)}}%</ng-template>
                                </span>
                                </div>
                                <span class="prv" i18n="@@previous-four-weeks">prv. 4 weeks</span>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
            </div>
            <div *ngIf="hasBedroom" class="slot auto-width delta-slot">
                <h3 class="left-aligned">Change in Time in<br>Bedroom</h3>
                <div class="slot time-change-delta">
                    <ng-container *ngIf="analytics?.averagePresence.is_presence_data_valid !== false; else notEnoughData">
                        <ng-container *ngIf="isNumber(analytics?.inBedroomDelta); else noData">
                            <div *ngIf="analytics?.inBedroomDelta; else noChange">
                                <div>
                                    <img class="room-type" src="assets/icons/room/delta-bedroom.svg">
                                    <ng-container *ngTemplateOutlet="deltaIcon; context: {value: analytics?.inBedroomDelta}"></ng-container>
                                    <span class="value">
                                        <ng-container *ngIf="Math.abs(analytics?.inBedroomDelta) > 500; else value">>500%</ng-container>
                                        <ng-template #value>{{Math.abs(analytics?.inBedroomDelta)}}%</ng-template>
                                    </span>
                                </div>
                                <span class="prv" i18n="@@previous-four-weeks">prv. 4 weeks</span>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
            </div>
            <div class="slot auto-width delta-slot">
                <h3 class="left-aligned">Change in Time Out<br>of Apartment</h3>
                <div class="slot time-change-delta">
                    <ng-container *ngIf="analytics?.averagePresence.is_presence_data_valid !== false; else notEnoughData">
                        <ng-container *ngIf="isNumber(analytics?.outOfApartmentDelta); else noData">
                            <div *ngIf="analytics?.outOfApartmentDelta; else noChange">
                                <div>
                                    <img class="room-type" src="assets/icons/room/delta-out.svg">
                                    <ng-container *ngTemplateOutlet="deltaIcon; context: {value: analytics?.outOfApartmentDelta}"></ng-container>
                                    <span class="value">
                                        <ng-container *ngIf="Math.abs(analytics?.outOfApartmentDelta) > 500; else value">>500%</ng-container>
                                        <ng-template #value>{{Math.abs(analytics?.outOfApartmentDelta)}}%</ng-template>
                                    </span>
                                </div>
                                <span class="prv" i18n="@@previous-four-weeks">prv. 4 weeks</span>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="!isSuiteView" class="row weekly" style="height: 166px;">
            <div class="slot auto-width" data-cy="averageResponseTime">
                <span class="cdk-visually-hidden" #averageResponseTimeTooltip i18n="@@average-response-time-tooltip">
                    The average time it takes for the care team in the facility to set a resolution to the alert. Benchmark time is customizable.
                </span>
                <h3><ng-container i18n="@@average-response-time">Average Response Time</ng-container> <img src="assets/icons/info.svg"
                                                                                                           [matTooltip]="averageResponseTimeTooltip.innerHTML" matTooltipClass="tooltip" matTooltipPosition="right"></h3>
                <div class="value with_icon" [attr.data-value]="analytics?.averageResponseTime.value">
                    <img class="value_icon" style="margin-top: 2px;" src="assets/icons/average_time.svg">
                    <ng-container *ngIf="analytics?.averageResponseTime">
                        {{analytics?.averageResponseTime.value / 1000 / 60 | number:'1.0-0'}} <span class="measurements" i18n="@@min">min</span>
                        <span class="benchmark">{{analytics?.dict.benchmark?.value}} min. benchmark</span>
                    </ng-container>
                </div>
                <div class="delta">
                    <ng-container *ngTemplateOutlet="delta; context: {delta: analytics?.averageResponseTime.delta, value: analytics?.averageResponseTime.value / 1000 / 60 | number:'1.0-0' | float, previousValue: analytics?.averageResponseTime.previousValue}"></ng-container>
                </div>
            </div>
            <div class="slot auto-width" data-cy="outOfApartments">
                <span class="cdk-visually-hidden" #averageTimeOutOfApartmentTooltip i18n="@@average-time-out-of-apartment-tooltip">
                    The average time in hours per day that the apartment is vacant. This can be an indicator of social engagement.
                </span>
                <h3><ng-container i18n="@@average-time-out-of-apartment">Average Time Out of Apartment</ng-container> <img src="assets/icons/info.svg"
                                                                                                                           [matTooltip]="averageTimeOutOfApartmentTooltip.innerHTML" matTooltipClass="tooltip" matTooltipPosition="right"></h3>
                <div class="value with_icon" [attr.data-value]="analytics?.outOfApartments.value">
                    <img class="value_icon" src="assets/icons/safe-exit.svg">
                    <ng-container *ngIf="analytics">
                        {{analytics?.outOfApartments.value / 1000 / 60 / 60 | number:'1.0-0'}} <span class="measurements" i18n="@@hours">hours</span>
                        <span class="average-day" i18n="@@average-day">Average / day</span>
                    </ng-container>
                </div>
                <div class="delta">
                    <ng-container *ngTemplateOutlet="delta; context: {delta: analytics?.outOfApartments.delta, value: analytics?.outOfApartments.value / 1000 / 60 / 60 | number:'1.0-0' | float, previousValue: analytics?.outOfApartments.previousValue}"></ng-container>
                </div>
            </div>
            <div class="slot">
                <div class="falls-graph-switch">
                    <a [class.active]="fallsByType === 'shift'" (click)="fallsByType = 'shift'">Shifts</a> / <a [class.active]="fallsByType === 'days'" (click)="fallsByType = 'days'">Days</a>
                </div>
                <ng-container *ngIf="fallsByType === 'shift'">
                    <h3 i18n="@@falls-by-shift">Falls by Shift</h3>
                    <ng-container *ngTemplateOutlet="fallsByShiftPerDay"></ng-container>
                    <div class="row" style="height: 100%; gap: 2px;">
                        <div class="slot falls-by-bhift" *ngFor="let shift of analytics?.fallsByShift?.grouped; let first=first" [class.with-left-border]="!first">
                            <img class="slot_icon" [src]="getFallsByShiftIcon(shift.shift_name)">
                            <div class="slot_context">
                                <span class="shift-name">{{shift.shift_name}}</span>
                                <span class="value pull-right">{{shift.data.value}}</span>
                                <div class="delta pull-right">
                                    <ng-container *ngTemplateOutlet="delta; context: {delta: shift.data.delta, value: shift.data.value, previousValue: shift.data.previousValue}"></ng-container>
                                </div>
                            </div>
                        </div>
                        <div class="slot" *ngIf="!analytics?.fallsByShift?.grouped.length">
                            <span class="value">-</span>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="fallsByType === 'days'">
                    <h3 i18n="@@falls-by-day">Falls by day</h3>
                    <ng-container *ngTemplateOutlet="fallsByShiftPerDay"></ng-container>
                    <app-chart *ngIf="analytics" type="bar" [data]="analytics?.fallsByShift.byDay"></app-chart>
                </ng-container>
            </div>
        </ng-container>
    </div>
</div>

<mat-card *ngIf="isLoading"
          class="loading"
>
    <mat-progress-spinner color="primary" mode="indeterminate">
    </mat-progress-spinner>
</mat-card>

<ng-template #potentialFalls>
    <div class="slot" style="max-width: 160px;" data-cy="potentialFalls">
            <span class="cdk-visually-hidden" #potentialFallsTooltip i18n="@@potential-falls-tooltip">
                Suspected falls detected by Vayyar's algorithm, including hidden/self-recovered falls.
            </span>
        <h3><ng-container i18n="@@potential-falls">Potential Falls</ng-container> <img src="assets/icons/info.svg"
                                                                                       [matTooltip]="potentialFallsTooltip.innerHTML" matTooltipClass="tooltip" matTooltipPosition="right"></h3>
        <div class="stack" style="margin: auto;">
            <span class="value">{{analytics?.potentialFalls.value ?? '-'}}</span>
            <div class="delta">
                <ng-container *ngTemplateOutlet="delta; context: {delta: analytics?.potentialFalls.delta, value: analytics?.potentialFalls.value, previousValue: analytics?.potentialFalls.previousValue}"></ng-container>
            </div>

            <span *ngIf="isSuiteView" class="prv" style="position: absolute; bottom: 20px;"><b>{{analytics?.potentialFallList.length}}</b>&nbsp;<ng-container i18n="@@in-last-six-months">in last 6 months</ng-container></span>
        </div>
        <a class="show-all-potential" (click)="showPotentialFalls()"><img src="assets/icons/pager-arrow.svg"></a>
    </div>
</ng-template>

<ng-template #delta let-delta="delta" let-value="value" let-previousValue=previousValue>
    <span class="prc" [class.inc]="delta > 0 && previousValue !== 0" [class.dec]="delta < 0 && previousValue !== 0">
        <img *ngIf="analytics && delta && previousValue !== 0" [src]="delta > 0 ? 'assets/icons/up.svg' : 'assets/icons/down.svg'">&nbsp;
        <ng-container *ngIf="!previousValue && value !== 0; else deltaTmpl">
            <span i18n="@@increased-from-zero">Increased from 0 to</span> {{value}}
        </ng-container>
        <ng-template #deltaTmpl>
            <ng-container *ngIf="delta === 0; else deltaValue" i18n="@@no-change">No change</ng-container>
            <ng-template #deltaValue>
                <ng-container *ngIf="Math.abs(delta) > 500; else value">>500%</ng-container>
                <ng-template #value>{{Math.abs(delta)}}%</ng-template>
            </ng-template>
        </ng-template>
    </span>
    <span class="prv" i18n="@@previous-four-weeks">prv. 4 weeks</span>
</ng-template>

<ng-template #deltaIcon let-value="value">
    <img *ngIf="analytics && value !== 0" [src]="value > 0 ? 'assets/icons/up.svg' : 'assets/icons/down.svg'">
</ng-template>

<ng-template #fallsByShiftPerDay>
    <a class="show-fall-by-shift-per-day" (click)="showFallsByShiftPerDay()"><img src="assets/icons/pager-arrow.svg"></a>
</ng-template>

<ng-template #noData>
    <span i18n="@@no-data-available" class="no-data">No data available</span>
</ng-template>

<ng-template #notEnoughData>
    <span i18n="@@not-enough-data" class="not-enough-data">Not enough data</span>
</ng-template>

<ng-template #noChange>
    <span i18n="@@no-change" class="no-change">No change</span>
</ng-template>
