export const environment = {
  applicationId: "fall-detector",
  production: true,
  showLogo: true,
  appleSignInEnabled: true,
  name: "Walabot-Home-Dev",
  projectId: "walabot-home-dev",
  multiRegion: true,
  get apiUrl() {
    return {
      us: `https://dev.vayyarhomeapisdev.com`,
      eu: `https://dev.eu.vayyarhomeapisdev.com`,
    };
  },
  registryRegion: "us-central1",
  region: "us-central1",
  fbConfig: {
    apiKey: "AIzaSyBMkgW0foLpCL0ctTvprdHR-hLHy3sDBiw",
    authDomain: "walabothome-app-cloud.firebaseapp.com",
    databaseURL: "https://walabothome-app-cloud.firebaseio.com",
    projectId: "walabothome-app-cloud",
    storageBucket: "walabothome-app-cloud.appspot.com",
    messagingSenderId: "242247651318",
    appId: "1:242247651318:web:e84b503e45b68840",
  },
  fcmPubKey:
    "BNNWUA-by2YOctgBdYwf8AOXL0k_tSxjhZq5382Vordy6gEAEty4LWxDPobqa1t_r5vWlG_dR81_rDo5mtSdXK4",
  learningModeDuration: 1000 * 60 * 60 * 24 * 14,
  thMinEventsForFirstDecision: 5,
  thNumOfDetectionsInChain: 4,
  sensitivityLevel: 0.78,
  callingDurationSec: 30,
  suspendDuration_sec: 900,
  bedExitPredictionThreshold: 0.9,
  bedExitNFramesToReset: 100,
  minTimeOfTarInFallLoc_sec: 30,
  durationUntilConfirm_sec: 52,
  enableTrackerTargetTelemetry: true,
  enableDoorEventTelemetry: false,
  enablePeakTelemetry: true,
  enableAboveThPointTelemetry: false,
  enableIslandPointTelemetry: false,
  enableHeightProfileTelemetry: true,
  enableOtfPointTelemetry: true,
  enableFallingTelemetry: true,
  enableSensitiveFallingTelemetry: true,
  enablePresenceTelemetry: true,
  enableImageParamsTelemetry: true,
  enableInterfererLocHistoryTelemetry: true,
  enableMtiParamsTelemetry: true,
  enableReferenceTelemetry: true,
};
